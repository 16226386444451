.search-results-container {
    margin-top: 65px;
}

.search-result-title {
    margin-bottom: 24px;
}

.rc-pagination-item-active, .rc-pagination-item-active a {
    background-color: #131045;
    color: white;
    border: none;
    border-radius: 14px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    height: auto !important;
}

.rc-pagination-item, .rc-pagination-item a {
    width: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    border-radius: 14px !important;
    height: auto !important;
}

.rc-pagination-item-active {
    border: none !important;
}

.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link {
    border-radius: 14px;
}
