.header-section {
    width: 100%;
    background-color: white;
}
.header-top {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    justify-content: space-between;
}
.header-logo {
    max-height: 80px;
}
.header-bottom {
    height: 52px;
    width: 100%;
    background-color: #0C2F73;
}

.option-double-nav-full-width .double-nav-alternative__menu .sb-nav--cta .sb-nav__list-cta {
    display: flex;
    justify-content: flex-end;
}

.sb-nav__list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.sb-nav {
    clear: none;
    line-height: inherit;
    max-height: none;
    overflow: visible;
    text-align: right;
    flex-shrink: 0;
}
.sb-section-opt-light div, .sb-section-opt-light h1, .sb-section-opt-light h2, .sb-section-opt-light h3, .sb-section-opt-light h4, .sb-section-opt-light h5, .sb-section-opt-light h6, .sb-section-opt-light p, .sb-section-opt-light span, .sb-section-opt-light input {
    color: #012e79;
}


.option-double-nav-full-width .double-nav-alternative__menu .sb-nav--cta .sb-nav__btn {
    margin: 0 0 0 24px;
}

@media (min-width: 544px) {
    .double-nav-alternative__menu .sb-nav--cta .sb-nav__item {
        border-top: none;
    }
}
.sb-nav__btn {
    margin: 0 0 0 24px;
}
.sb-nav__item {
    line-height: 1;
    margin-left: 24px;
    padding: 0;
    position: relative;
}

.sb-nav__list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.sb-nav {
    clear: none;
    line-height: inherit;
    max-height: none;
    overflow: visible;
    text-align: right;
    flex-shrink: 0;
}
.sb-section-opt-light div, .sb-section-opt-light h1, .sb-section-opt-light h2, .sb-section-opt-light h3, .sb-section-opt-light h4, .sb-section-opt-light h5, .sb-section-opt-light h6, .sb-section-opt-light p, .sb-section-opt-light span, .sb-section-opt-light input {
    color: #012e79;
}

.option-button-size-md .sb-button, .option-button-size-md .sb-button:link, .option-button-size-md .sb-button:visited {
    padding: 10px 30px;
    min-height: 50px;
}

.sb-section-opt-light .sb-button--nav, .sb-section-opt-light .sb-button--nav:link, .sb-section-opt-light .sb-button--nav:visited {
    color: #011b46;
    border-color: #f28f00;
    background-color: #ffa626;
}

.sb-button--nav, .sb-button--nav:link, .sb-button--nav:visited {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0px;
    text-transform: none;
    border-radius: 0px;
    border-width: 2px;

}
.sb-button, .sb-button:link, .sb-button:visited {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    transition: all .15s ease-in-out;
    text-decoration: none;
    margin: 0;
    min-height: 50px;
    padding: 10px 30px;
    border: 2px solid;
    border-radius: 0px;
    color: #011b46;
    border-color: #f28f00;
    background-color: #ffa626;
}

.sb-button:hover {
    color: #0141ac;
    border-color: #0141ac;
    background-color: #fff;
}

.sb-button:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #0141ac;
}

.sb-section-opt-light a, .sb-section-opt-light a:link, .sb-section-opt-light a:visited {
    color: #f28f00;
}

.sb-section-opt-light .sb-button--nav:hover, .sb-section-opt-light .sb-button--nav:link:hover, .sb-section-opt-light .sb-button--nav:visited:hover {
    color: #0141ac;
    border-color: #0141ac;
    background-color: #fff;
}

.sb-nav--cta {
}
