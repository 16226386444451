.outfitter-card-container {
    background-color: #F5BD6B;
    border-radius: 4px;
    height: 250px;
    text-align: center;
    margin-bottom: 20px;
}

.outfitter-card-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.outfitter-card-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    margin-bottom: 13px;
    color: #333;
}

.outfitter-card-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #a67f4a;
}

.outfitter-card-description {
    padding: 50px
}
