.content-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 696px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    text-align: center;
}
.content-header-title {
    font-size: 44px;
    line-height: 48px;
    margin-bottom: 10px;
}
.content-header-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #AAA;
}
