.outfitter-details-container {
    margin-top: 40px;
}
.outfitter-details-description {
    white-space: pre-line;
}

.outfitter-details-right-container {
    text-align: left;
}

.outfitter-details-image {
    object-fit: contain;
    margin-bottom: 20px;
    width: 100%;
}

.outfitter-details-stats {
    text-align: left;
}

.outfitter-species-link {
    color: black;
}
