.search-filter-container {
    margin-top: 65px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 14px;

}

.search-filter-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #333333;
}

.filter-accord-header {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

}

.search-filter-container .form-check-input:checked {
    background-color: #131045;
}

.search-filter-container .form-check-label {
    font-size: 18px;
    font-weight: 600;
}

.filter-accord-header a {
    color: #131045;
}

.filter-accordion .accordion-button {
    font-weight: 600;
    color: #131045;
    font-size: 24px;
}

.filter-accordion .accordion-button:not(.collapsed) {
    background-color: white;
    border-color: #F0F0F0;
}

.search-filter-sidebar-title {
    font-size: 40px;
    margin-bottom: 24px;
}
