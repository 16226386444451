.footer-container {
    padding-top: 50px;
    height: 315px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.95)), url("https://cdn.b12.io/client_media/ad79D2TZ/a90d3504-8c73-11eb-baa5-0242ac110003-jpg-hero_image.jpeg");
}
.sb-section-opt-light .sb-footer-simple-newsletter__title {
    color: #012e79;
}
.sb-section-opt-light div, .sb-section-opt-light h1, .sb-section-opt-light h2, .sb-section-opt-light h3, .sb-section-opt-light h4, .sb-section-opt-light h5, .sb-section-opt-light h6, .sb-section-opt-light p, .sb-section-opt-light span, .sb-section-opt-light input {
    color: #012e79;
}
.sb-footer-simple-newsletter__title {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #012e79;
}

.sb-footer__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sb-footer__location, .sb-footer__location a, .sb-footer__location p, .sb-footer__contact, .sb-footer__contact a, .sb-footer__contact p, .sb-footer__hours, .sb-footer__hours a, .sb-footer__hours p, .sb-footer__social, .sb-footer__social a, .sb-footer__social p {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0px;
    text-transform: none;
}

.footer-logo {
    max-height: 80px;
}

.form__input {
    border-radius: 2px;
    border-style: solid;
    border-width: 2px;
    cursor: text;
    display: block;
    margin: 0;
    padding: 8px 10px;
    width: 100%;
    outline: 0;
}

.sb-section-opt-light div, .sb-section-opt-light h1, .sb-section-opt-light h2, .sb-section-opt-light h3, .sb-section-opt-light h4, .sb-section-opt-light h5, .sb-section-opt-light h6, .sb-section-opt-light p, .sb-section-opt-light span, .sb-section-opt-light input {
    color: #012e79;
}

.form__input {
    background-color: #fff;
    color: #011b46;
    border-color: #0141ac;
}

.newsletter-signup-form__input, .newsletter-signup-form__input:focus {
    margin: 0;
    min-height: 50px;
}


