.category-card-container {
    margin-bottom: 100px;
    text-align: center;
}

.category-card-image {
    background: #E8E8E8;
    object-fit: cover;
    height: 450px;
    width: 100%;
    max-width: 1000px;
    margin-bottom: 18px;
}

.category-card-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 24px;
    text-align: center;
    color: #333333;
    margin-bottom: 18px;
}
