.ad-container {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 70px;
}

.ad-image-horizontal-large {
    object-fit: cover;
    width: 930px;
    height: 180px;
}


.ad-image-horizontal-small {
    object-fit: cover;
    width: 729px;
    height: 90px;
}


.ad-image-vertical {
    object-fit: cover;
    width: 300px;
    height: 600px;
}


.ad-image-square {
    object-fit: cover;
    width: 250px;
    height: 250px;
}

.ad-image {
    background-color: #FEFEFE;
    object-fit: contain;
}
