.search-card-image {
    background-color: gray;
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: 14px;
}

.search-card-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #333333;
}

.search-card-container {
    margin-bottom: 28px;
}

.search-loading-container {
    width: 100%;
    height: 250px;
    margin-bottom: 14px;
}
