.header-navigation-bottom a {
    font-family: "Source Sans Pro", sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.75px;
    vertical-align: middle;
    transition: all 0.15s ease-in-out;
}

.header-navigation-bottom a:hover {
    color: white;

}

.header-navigation-bottom. .nav-link:after {
    background-color: #ffa626;
}

.header-bottom {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
