.outfitter-card-horizontal-container {
    background-color: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outfitter-card-horizontal-image {
    height: 150px;
    object-fit: cover;
    margin: 20px;
}

.outfitter-card-horizontal-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    color: #333;
    text-overflow: ellipsis;
    overflow: hidden;
}

.outfitter-card-horizontal-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 74px;
}

.outfitter-card-horizontal-description {
    padding: 50px;
}

.outfitter-card-horizontal-right-container {
    text-align: left;
    padding: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outfitter-card-horizontal-location {
    color: #949494;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
}
