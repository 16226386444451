.homepage-hero-container {
    margin-top: 40px;
}
.hero-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #AAA;
}

.hero-copy-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: left;
}

.hero-copy {
    max-width: 500px;
}

.hero-search-input {
    font-size: 18px;
    height: 50px;
    border-radius: 4px;
    border-width: 0;
    background-color: rgba(235, 235, 235, 0.6);
}

.hero-search-button {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #0E2142;
    background: #F3A947;
    border-radius: 0 4px 4px 0;
    height: 50px;
    width: 200px;
}
