.animal-section-container {
    margin-top: 40px;
}

.animal-section-container .carousel-slider {
    height: 350px;
    width: 100%;
    border-radius: 4px;
}

.animal-section-container .carousel .thumb {
    border-radius: 4px;
}

.animal-section-title {
    font-size: 44px;
    line-height: 48px;
    color: #333333;
    text-transform: uppercase;
}

.animal-location {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    color: #AAA;
    margin-bottom: 15px;
}

.animal-location-description, .animal-summary {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
}

.animal-disclaimer, .animal-disclaimer a {
    color: #AAA;
}

.animal-footer-ad-container {
    margin-top: 30px;
    padding-top: 65px;
    padding-bottom: 65px;
    background-color: #fbfbfb;
}

.animal-section-map {
    margin-top: 40px;
}
